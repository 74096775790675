import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchTariffs(context, payload) {
      return axios.get('settings', payload)
    },
    async tariffUp(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        html: `Тариф "<b>${payload.name}</b>"<br>Стоимость тарифа: ${payload.price} руб.<br>Перейти к оплате?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          // eslint-disable-next-line
          return axios.post(`payment/${payload._id}`)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            window.location.replace(data)
          }
        }

        return result
      })
    },
  },
}
