<template>
  <section
    v-if="tariffs"
    id="pricing-plan"
  >
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <!-- title text and switch button -->
    <div class="text-center">
      <h1>
        Ваш тарифный план: Базовый
      </h1>
      <p class="mb-2 pb-75">
        Лимиты тарифа: максимальное количество проектов - 1, максимальное количество каналов трафика - 1, загрузка документов недоступна
      </p>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row class="match-height">
          <b-col
            md="4"
          >
            <b-card
              align="center"
            >
              <!--/ img -->
              <h3>{{ tariffs[0].name }}</h3>
              <b-card-text>
                {{ tariffs[0].subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">Р</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ tariffs[0].price }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/месяц</sub>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in tariffs[0].benefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->
              <template #footer>
                <b-button
                  v-if="user.tariff.name === 'Базовый'"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  variant="outline-success"
                  :disabled="true"
                >
                  Ваш текущий тариф
                </b-button>
              </template>
              <!-- buttons -->
            </b-card>
          </b-col>
          <b-col
            md="4"
          >
            <b-card
              align="center"
            >
              <!--/ img -->
              <h3>{{ tariffs[1].name }}</h3>
              <b-card-text>
                {{ tariffs[1].subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">Р</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ tariffs[1].price }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/месяц</sub>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in tariffs[1].benefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->
              <template #footer>
                <b-button
                  v-if="user.tariff.name !== 'Продвинутый'"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  variant="outline-primary"
                  @click="tariffUp(tariffs[1])"
                >
                  Повысить тариф
                </b-button>
                <b-button
                  v-else-if="user.tariff.name === 'Продвинутый'"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  variant="outline-success"
                  :disabled="true"
                >
                  Ваш текущий тариф
                </b-button>
              </template>
              <!-- buttons -->
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BListGroup, BListGroupItem, BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useTariffs from './useTariffs'
import tariffsStoreModule from './tariffsStoreModule'

export default {
  components: {
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-tariffs'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, tariffsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const {
      user,
      loading,
      tariffs,
      fetchTariffs,
      tariffUp,
    } = useTariffs()

    fetchTariffs()

    return {
      user,
      loading,
      tariffs,
      tariffUp,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
.card .card-footer {
  padding-top: 0;
  border: none;
}
</style>
