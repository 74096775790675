import { ref } from '@vue/composition-api'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useTariffs() {
  const user = store.getters['app/user']
  const loading = ref(false)
  const tariffs = ref(null)

  const fetchTariffs = () => {
    loading.value = true
    store
      .dispatch('app-tariffs/fetchTariffs')
      .then(response => {
        const { data } = response
        tariffs.value = data.tariffs
        loading.value = false
      })
      .catch(() => {
        tariffs.value = null
        loading.value = false
      })
  }

  const tariffUp = tariff => {
    store.dispatch('app-tariffs/tariffUp', tariff)
  }

  return {
    user,
    loading,
    tariffs,
    fetchTariffs,
    tariffUp,
  }
}
